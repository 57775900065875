import React, { useContext } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { MenuContext } from "../../context/MenuContext"
import useIntersect from "../../hooks/useIntersect"
import "./SectionImage.scss"

const SectionImage = ({ image, alt = "", imagePosX, imagePosY }) => {
  const { menuOpen } = useContext(MenuContext)
  const [intersectionRef, entry] = useIntersect({})
  return (
    <div
      ref={intersectionRef}
      className={`
        iliad__section--content-image-container
        ${entry.isIntersecting && !menuOpen ? "animation-section-image" : ""}
        ${imagePosX ? `iliad__section--content-image-posX-${imagePosX}` : ""}
        ${imagePosY ? `iliad__section--content-image-posY-${imagePosY}` : ""}
      `}
    >
      {image && <GatsbyImage image={image} alt={alt} />}
    </div>
  )
}

export default SectionImage

export const query = graphql`
  fragment SectionImageFragment on MarkdownRemarkFrontmatterSectionsContent {
    contentImage {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
