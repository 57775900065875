import React from 'react'
import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import PageLayout from '../layouts/PageLayout'
import Section from 'components/Section/Section'
import Seo from 'components/Seo'
import SectionImage from 'components/Section/SectionImage'

const BlogCollection = loadable(() => import('../components/BlogCollection'))

const NewsPage = ({
  data: {
    allFile: { blogs },
    markdownRemark: {
      frontmatter: { meta, sections = [] },
    },
  },
  location: { pathname },
}) => {
  const imagePath = getSrc(meta.ogImage)
  const sectionsMap = sections.map(
    ({ content, background, ctaButton }, index) => {
      const contentImage = getImage(content.contentImage)
      const backgroundImage = getImage(background.backgroundImage)

      return (
        <Section
          key={background.backgroundColor}
          index={index}
          fullHeight
          id={`page-section-${index}`}
          as={index === 0 ? 'header' : 'section'}
          colorTheme={background.backgroundColor}
          backgroundImage={backgroundImage}
          colorOverlay={background.colorOverlay}
          content={content}
          ctaButton={ctaButton}
          pathname={pathname}
          image={
            <SectionImage
              image={contentImage}
              imagePosX={content.imagePosX}
              imagePosY={content.imagePosY}
            />
          }
        />
      )
    }
  )

  return (
    <PageLayout pathname={pathname}>
      <Seo
        title={meta.title}
        description={meta.metaDescription}
        ogImage={imagePath}
        meta={meta}
        pathname={pathname}
      />
      {sectionsMap}
      <Section
        index={sections.length}
        id={`page-section-${sections.length}`}
        as='section'
        colorTheme='lace'
        fullHeight
        pathname={pathname}>
        <BlogCollection blogs={blogs} />
      </Section>
    </PageLayout>
  )
}

export default NewsPage

export const query = graphql`
  query NewsPageQuery {
    markdownRemark(frontmatter: { title: { eq: "news" } }) {
      id
      frontmatter {
        meta {
          title
          metaDescription
          ogImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        sections {
          content {
            header
            subheader
            posX
            posY
            ...SectionImageFragment
            imagePosX
            imagePosY
          }
          background {
            backgroundColor
            backgroundImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            colorOverlay
          }
          ctaButton {
            buttonText
            buttonURL
            buttonAction
          }
        }
      }
    }
    allFile(
      filter: { sourceInstanceName: { eq: "blog" } }
      sort: { fields: birthtime, order: DESC }
    ) {
      blogs: nodes {
        id
        childMarkdownRemark {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            description
            headerImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
